class Survey {
    private static instance: Survey;
    private options: Map<string, string>;
    private lessonLearnedTemplate: HTMLDivElement;
    private dynamicHolder: HTMLElement;
    private constructor() {

    }

    static getInstance() {
        if (!Survey.instance) {
            Survey.instance = new Survey();
        }
        return Survey.instance;
    }

    public init() {
        this.options = new Map();
    }

    public initDynamicAnswer() {
        this.fetchOptions();
        this.dynamicHolder = document.querySelector('div[name="holder"]');
        this.listenToAddButton();
    }

    private listenToAddButton(): void {
        document.querySelector('button[name="add"]').addEventListener('click', () => {
            this.addDynamicAnswer();
            console.log("adding");
        });
    }

    private addDynamicAnswer() {
        if (!this.dynamicHolder) throw Error("no Holder found");
        this.dynamicHolder.append(this.createAnswerTemplate());
    }


    async fetchOptions() {
        fetch("/survey/question/adddynamicanswer").then(
            response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }
        ).then(data => {
            this.options = new Map();
            data.forEach((el: { key: string, value: string }) => {
                this.options.set(el.key, el.value);
            });
            this.addDynamicAnswer();            
        })
    }

    private createAnswerTemplate(): HTMLElement {
        const fieldset = document.createElement("fieldset");
        const uuid = this.getUniqueId(4);
        fieldset.name = uuid;
        const template = document.createElement("div");
        template.classList.add("box", "has-background-light", "content");
        template.title = "dynAnserWrapper";
        template.append(fieldset);
        const deleteButton = document.createElement("button");
        deleteButton.classList.add("delete", "is-pulled-right");
        deleteButton.title = "Diesen Block entfernen!"
        deleteButton.type = "button";
        deleteButton.addEventListener('click', () => {
            template.remove();
        });
        fieldset.append(deleteButton);


        //Create and append select list
        const selectField = document.createElement("div");
        selectField.classList.add("field");
        selectField.title = "Bitte die betreffende Abteilung wählen";

        const selectLabel = document.createElement("label");
        selectLabel.classList.add("label");
        selectLabel.textContent = "Teilnehmer nach Hauptprozess";
        selectField.append(selectLabel);

        const selectControl = document.createElement("div");
        selectControl.classList.add("control");
        selectField.append(selectControl);


        var selectWrapper = document.createElement("div");
        selectWrapper.classList.add("select", "block");
        selectControl.append(selectWrapper);

        var selectList = document.createElement("select");
        selectList.name = `dynAnswer[${uuid}][area]`;
        selectWrapper.append(selectList);
        fieldset.append(selectField);

        //Create and append the options
        this.options.forEach((values, keys) => {
            var option = document.createElement("option");
            option.value = values;
            option.text = keys;
            selectList.append(option);
        })

        const textField = document.createElement("div");
        textField.classList.add("field");
        textField.title = "Bitte das Gelernte eintragen";

        const textLabel = document.createElement("label");
        textLabel.classList.add("label");
        textLabel.textContent = "Lesson learned:";
        textField.append(textLabel);
        
        const textControl = document.createElement("div");
        textControl.classList.add("control");
        textField.append(textControl);

        var textWrapper = document.createElement("div");
        textWrapper.classList.add("control");
        var textArea = document.createElement("textarea");
        textArea.name = `dynAnswer[${uuid}][lesson]`;
        textArea.classList.add("textarea");
        textWrapper.append(textArea);
        textControl.append(textWrapper);
        fieldset.append(textField);

        return template;
    }

    private getLastNumber(): number {
        if (!this.dynamicHolder) throw Error("no Holder found");
        return this.dynamicHolder.querySelectorAll('div[title="dynAnserWrapper"]').length + 1;
    }

    /**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a
 */
    private getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

}

function initDropdownListener() {
    // Get all "navbar-burger" elements
    const navbarBurgers = document.querySelectorAll('.navbar-burger');

    // Add a click event on each of them
    navbarBurgers.forEach((el: HTMLElement) => {
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

        });
    });

    // Get all dropdown elements
    const dropdowns = document.querySelectorAll('.navbar-item.has-dropdown');
    dropdowns.forEach((el: HTMLElement) => {
        el.addEventListener('click', () => {
            el.classList.toggle('is-mobile-active');
        });
    });

    window.addEventListener('resize', () => {
        dropdowns.forEach((el: Element) => {
            el.classList.remove('is-mobile-active');
        })
    });
}

const survey: Survey = Survey.getInstance();
document.addEventListener('DOMContentLoaded', () => {
    survey.init();
    initDropdownListener();
    if (document.querySelector('.dynamic-question-main')) {
        survey.initDynamicAnswer();
    }


});